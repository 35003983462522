import request from '@/utils/request'
const API = '/api/tct'

export function TCT(params, action) {
  if (action === 'create') return create(params)
  if (action === 'update') return update(params)
  if (action === 'remove') return remove(params)
  if (action === 'show') return show(params)
  if (action === 'search') return search(params)
  else return all(params)
}

function all(params) {
  return request({
    url: API,
    method: 'get',
    params
  })
}

function create(params) {
  return request({
    url: API,
    method: 'post',
    data: params
  })
}

function update(params) {
  return request({
    url: API + '/' + params.id,
    method: 'put',
    data: params
  })
}

function show(params) {
  return request({
    url: API + '/' + params.id,
    method: 'get'
  })
}
function search(params) {
  return request({
    url: API + '/' + params,
    method: 'get'
  })
}

function remove(params) {
  return request({
    url: API + '/' + params.id,
    method: 'delete'
  })
}
