<template>
  <div style="margin: 20px;">
    <h3>Transfer Certificate of Title<a type="text" style="font-size: 14px; color: green;" @click="add()"> New <i class="fas fa-plus-square"/></a></h3>
    <el-card shadow="never">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-input v-model="reg_num_s" size="medium" placeholder="Registered number" clearable />
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Search" placement="top">
                <el-button :icon="icon_search" type="primary" size="medium" @click="search()" />
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Refresh" placement="top">
                <el-button :icon="icon_refresh" type="info" size="medium" @click="refresh()" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-checkbox v-model="naf">No Attached File</el-checkbox>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-table v-loading="loading" ref="singleTable" :data="tct" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column type="expand" label="..." width="48">
            <template slot-scope="props">
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <p class="para"><strong>Registered number: </strong>{{ props.row.reg_num }}</p>
                  <p v-if="props.row.created_by_personnel" class="para">
                    <strong>Added by: </strong>
                    <span :title="getFormattedDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.created_by_personnel.name }}</span>
                  </p>
                  <p v-if="props.row.updated_by_personnel" class="para">
                    <strong>Updated by: </strong>
                    <span :title="getFormattedDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.updated_by_personnel.name }}</span>
                  </p>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="reg_num" label="Registered number" width="" />
          <el-table-column width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.path" style="word-break: break-word"><el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">File Uploaded</el-tag></span>
              <span v-else style="word-break: break-word"><el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operation (s)" width="150" align="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip v-if="scope.row.path" :key="'iftptct1' + scope.row.id" content="View Attached File" placement="top">
                  <el-button :key="'ifbntct2' + scope.row.id" type="primary" icon="el-icon-document" size="mini" style="margin-right: 10px;" @click="viewFile(scope.row.path)" />
                </el-tooltip>
                <el-tooltip :key="'tptct3' + scope.row.id" content="Edit" placement="top">
                  <el-button :key="'bntct4' + scope.row.id" type="primary" icon="el-icon-edit" size="mini" style="margin-right: 10px;" @click="edit(scope.$index)" />
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <div align="center" style="margin-top: 10px;">
          <el-pagination :page-size="meta.per_page" :pager-count="5" :total="meta.total" :current-page.sync="meta.page" layout="total, prev, pager, next" @current-change="getAll()" />
        </div>
      </el-row>
    </el-card>
    <el-dialog v-if="selected" :visible.sync="editable" :title="title" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" top="5vh" width="30%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form ref="selected" :model="selected" :rules="rules">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="Registered number" prop="reg_num">
                <el-input v-if="editable" v-model="selected.reg_num" :autosize="{ minRows: 2 }" type="textarea" maxlength="30" show-word-limit placeholder="Please input registered number" style="word-break: keep-all;" />
              </el-form-item>
              <el-form-item label="Attached File">
                <tct-uploader-component v-if="editable" :path.sync="selected.path" class="my-upload-job" @setAttachment="selected.path = $event" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('selected')">Save</el-button>
        <el-button type="danger" size="medium" @click="submitClose('selected')">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { TCT } from '@/api/records/tct'
import { Notification } from 'element-ui'
import TctUploaderComponent from '@/views/layout/components/Records/TctUploaderComponent'

export default {
  name: 'TCT',
  components: { TctUploaderComponent },
  data() {
    return {
      loading: false,
      meta: {
        page: 1
      },
      tct: [],
      editable: false,
      reg_num_s: null,
      title: null,
      naf: false,
      path_s: null,
      selected: {
        reg_num: null,
        path: null
      },
      rules: {
        reg_num: [
          { required: true, message: 'Year is required' }
        ]
      },
      base_url: this.$store.getters.base_url,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      view_file: null,
      view_attach_form: false
    }
  },
  watch: {
    naf: function() {
      this.meta.page = 1
      if (this.naf === true) {
        this.reg_num_s = null
        this.path_s = 1
      } else {
        this.reg_num_s = null
        this.path_s = null
      }
      this.getAll()
    }
  },
  created: function() {
    this.getAll()
  },
  methods: {
    getAll() {
      this.loading = true
      this.selected = null
      TCT({
        page: this.meta.page,
        reg_num: this.reg_num_s,
        path: this.path_s
      }).then(res => {
        this.tct = res.data.tct
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.reg_num_s = null
      this.naf = false
      this.path_s = null
      this.meta.page = 1
      this.getAll()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          Notification.error({
            title: 'Error',
            message: 'Unable to save',
            duration: 5 * 1000
          })
          return false
        }
      })
    },
    submitClose(formName) {
      this.$refs[formName].resetFields()
      this.editable = false
    },
    // viewFile(file) {
    //   window.open(this.base_url + '/records/tct/download/' + file, '_blank')
    // },
    viewFile(path) {
      this.view_file = this.base_url + '/records/tct/download/' + path
      this.view_attach_form = true
    },
    closeFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    add() {
      this.title = 'New Transfer Certificate of Title'
      this.selected = {}
      this.selected.orig_path = null
      this.editable = true
    },
    edit(index) {
      this.title = 'Edit Transfer Certificate of Title'
      this.selected = Object.assign({}, this.tct[index])
      this.selected.orig_path = this.selected.path
      this.editable = true
    },
    save: function() {
      const action = this.selected.id ? 'update' : 'create'
      TCT(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Transfer Certificate of Title has been saved successfully',
            duration: 5 * 1000
          })
          this.submitClose('selected')
          this.getAll()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    handleCurrentChange(val) {
      this.currentRow = val
    }
  }
}
</script>
